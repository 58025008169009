import { useState, useEffect } from 'react';

import {
  IdValue,
  MainDataValue,
  FundListValue,
  PaymentCategoryValue,
  IncomeCategoryValue
} from '../FirebaseType';

import Search from '../common/Search';
import SummaryTable from '../common/SummaryTable';

import { judgeType } from '../util/Firebase';

interface SummaryMonthProps {
  mainData: IdValue<MainDataValue>[] | undefined;
  fundList: IdValue<FundListValue>[] | undefined;
  paymentCategory: IdValue<PaymentCategoryValue>[] | undefined;
  incomeCategory: IdValue<IncomeCategoryValue>[] | undefined;
  searchForm: any;
  loginUserUid: string;
}

function SummaryMonth(props: SummaryMonthProps) {
  const [paymentSummaryMonth, setPaymentSummaryMonth] = useState<Map<string, Map<string, number>>>();
  const [paymentSummaryUnregularMonth, setPaymentSummaryUnregularMonth] = useState<Map<string, Map<string, number>>>();
  const [paymentSummaryRegularMonth, setPaymentSummaryRegularMonth] = useState<Map<string, Map<string, number>>>();

  const [incomeSummaryMonth, setIncomeSummaryMonth] = useState<Map<string, Map<string, number>>>();
  const [incomeSummaryUnregularMonth, setIncomeSummaryUnregularMonth] = useState<Map<string, Map<string, number>>>();
  const [incomeSummaryRegularMonth, setIncomeSummaryRegularMonth] = useState<Map<string, Map<string, number>>>();

  const [transferSummaryMonth, setTransferSummaryMonth] = useState<Map<string, Map<string, number>>>();

  useEffect(() => {
    if(props.mainData && props.fundList && props.paymentCategory && props.incomeCategory) {
      const paymentCategoryIrregular = props.paymentCategory
        .filter((idVal) => (!idVal.value.regular))
        .map((idVal) => (idVal.value.name));
      const paymentCategoryRegular = props.paymentCategory
        .filter((idVal) => (idVal.value.regular))
        .map((idVal) => (idVal.value.name));
      const fundListPayment = props.fundList.map((idVal) => (idVal.value.name));

      const incomeCategoryIrregular = props.incomeCategory
        .filter((idVal) => (!idVal.value.regular))
        .map((idVal) => (idVal.value.name));
      const incomeCategoryRegular = props.incomeCategory
        .filter((idVal) => (idVal.value.regular))
        .map((idVal) => (idVal.value.name));
      const fundListIncome = props.fundList.map((idVal) => (idVal.value.name));

      const fundListTransferFrom = props.fundList.map((idVal) => (idVal.value.name));
      const fundListTransferTo = props.fundList.map((idVal) => (idVal.value.name));

      props.mainData.forEach((idVal) => {
        const type = judgeType(idVal);
        switch(type) {
          case 'payment':
            if(!idVal.value.isRegular) {
              if(!paymentCategoryIrregular.includes(idVal.value.category)) {
                paymentCategoryIrregular.push(idVal.value.category);
              }
            }
            else {
              if(!paymentCategoryRegular.includes(idVal.value.category)) {
                paymentCategoryRegular.push(idVal.value.category);
              }
            }
            if(!fundListPayment.includes(idVal.value.from)) {
              fundListPayment.push(idVal.value.from);
            }
            break;
          case 'income':
            if(!idVal.value.isRegular) {
              if(!incomeCategoryIrregular.includes(idVal.value.category)) {
                incomeCategoryIrregular.push(idVal.value.category);
              }
            }
            else {
              if(!incomeCategoryRegular.includes(idVal.value.category)) {
                incomeCategoryRegular.push(idVal.value.category);
              }
            }
            if(!fundListIncome.includes(idVal.value.to)) {
              fundListIncome.push(idVal.value.to);
            }
            break;
          case 'transfer':
            if(!fundListTransferFrom.includes(idVal.value.from)) {
              fundListTransferFrom.push(idVal.value.from);
            }
            if(!fundListTransferTo.includes(idVal.value.to)) {
              fundListTransferTo.push(idVal.value.to);
            }
            break;
          case 'evaluation':
            break;
          default:
            break;
        }
      });

      const paymentSummaryMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計'].forEach((category) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListPayment].forEach((fundList) => {
          fundListWork.set(fundList, 0);
        })
        paymentSummaryMonthWork.set(category, fundListWork);
      });
      const paymentSummaryUnregularMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計（不定期）', ...paymentCategoryIrregular].forEach((category) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListPayment].forEach((fundList) => {
          fundListWork.set(fundList, 0);
        })
        paymentSummaryUnregularMonthWork.set(category, fundListWork);
      });
      const paymentSummaryRegularMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計（定期）', ...paymentCategoryRegular].forEach((category) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListPayment].forEach((fundList) => {
          fundListWork.set(fundList, 0);
        })
        paymentSummaryRegularMonthWork.set(category, fundListWork);
      });

      const incomeSummaryMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計'].forEach((category) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListPayment].forEach((fundList) => {
          fundListWork.set(fundList, 0);
        })
        incomeSummaryMonthWork.set(category, fundListWork);
      });
      const incomeSummaryUnregularMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計（不定期）', ...incomeCategoryIrregular].forEach((category) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListPayment].forEach((fundList) => {
          fundListWork.set(fundList, 0);
        })
        incomeSummaryUnregularMonthWork.set(category, fundListWork);
      });
      const incomeSummaryRegularMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計（定期）', ...incomeCategoryRegular].forEach((category) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListPayment].forEach((fundList) => {
          fundListWork.set(fundList, 0);
        })
        incomeSummaryRegularMonthWork.set(category, fundListWork);
      });

      const transferSummaryMonthWork: Map<string, Map<string, number>> = new Map();
      ['合計', ...fundListTransferFrom].forEach((fundListFrom) => {
        const fundListWork: Map<string, number> = new Map();
        ['合計', ...fundListTransferTo].forEach((fundListTo) => {
          fundListWork.set(fundListTo, 0);
        })
        transferSummaryMonthWork.set(fundListFrom, fundListWork);
      });

      props.mainData
        .filter((item) => !item.value.isDisabled)
        .filter((item) => !item.value.isAdvance)
        .forEach((idVal) => {
          const type = judgeType(idVal);
          switch(type) {
            case 'payment':
              if(!idVal.value.isRegular) {
                paymentSummaryUnregularMonthWork.get(idVal.value.category)?.set(
                  idVal.value.from,
                  (paymentSummaryUnregularMonthWork.get(idVal.value.category)?.get(idVal.value.from) || 0) + idVal.value.amount
                );
              }
              else {
                paymentSummaryRegularMonthWork.get(idVal.value.category)?.set(
                  idVal.value.from,
                  (paymentSummaryRegularMonthWork.get(idVal.value.category)?.get(idVal.value.from) || 0) + idVal.value.amount
                );
              }
              break;
            case 'income':
              if(!idVal.value.isRegular) {
                incomeSummaryUnregularMonthWork.get(idVal.value.category)?.set(
                  idVal.value.to,
                  (incomeSummaryUnregularMonthWork.get(idVal.value.category)?.get(idVal.value.to) || 0) + idVal.value.amount
                );
              }
              else {
                incomeSummaryRegularMonthWork.get(idVal.value.category)?.set(
                  idVal.value.to,
                  (incomeSummaryRegularMonthWork.get(idVal.value.category)?.get(idVal.value.to) || 0) + idVal.value.amount
                );
              }
              break;
            case 'transfer':
              transferSummaryMonthWork.get(idVal.value.from)?.set(
                idVal.value.to,
                (transferSummaryMonthWork.get(idVal.value.from)?.get(idVal.value.to) || 0) + idVal.value.amount
              );
              break;
            case 'evaluation':
              break;
            default:
              break;
          }
        });

      paymentSummaryUnregularMonthWork.get('合計（不定期）')?.forEach((value, key) => {
        paymentSummaryUnregularMonthWork.get('合計（不定期）')?.set(key,
          Array.from(paymentSummaryUnregularMonthWork.entries())
            .filter(([k, v]) => k !== '合計（不定期）')
            .reduce((sum, [k, v]) => sum + (v.get(key) || 0), 0)
        );
      });
      paymentSummaryUnregularMonthWork.forEach((category) => {
        category.set('合計',
          Array.from(category.entries())
            .filter(([key, value]) => key !== '合計')
            .reduce((sum, [key, value]) => sum + value, 0)
        );
      });
      paymentSummaryRegularMonthWork.get('合計（定期）')?.forEach((value, key) => {
        paymentSummaryRegularMonthWork.get('合計（定期）')?.set(key,
          Array.from(paymentSummaryRegularMonthWork.entries())
            .filter(([k, v]) => k !== '合計（定期）')
            .reduce((sum, [k, v]) => sum + (v.get(key) || 0), 0)
        );
      });
      paymentSummaryRegularMonthWork.forEach((category) => {
        category.set('合計',
          Array.from(category.entries())
            .filter(([key, value]) => key !== '合計')
            .reduce((sum, [key, value]) => sum + value, 0)
        );
      });
      paymentSummaryMonthWork.get('合計')?.forEach((value, key) => {
        paymentSummaryMonthWork.get('合計')?.set(key,
          (paymentSummaryUnregularMonthWork.get('合計（不定期）')?.get(key) || 0) +
            (paymentSummaryRegularMonthWork.get('合計（定期）')?.get(key) || 0)
        );
      });
      setPaymentSummaryMonth(paymentSummaryMonthWork);
      setPaymentSummaryUnregularMonth(paymentSummaryUnregularMonthWork);
      setPaymentSummaryRegularMonth(paymentSummaryRegularMonthWork);

      incomeSummaryUnregularMonthWork.get('合計（不定期）')?.forEach((value, key) => {
        incomeSummaryUnregularMonthWork.get('合計（不定期）')?.set(key,
          Array.from(incomeSummaryUnregularMonthWork.entries())
            .filter(([k, v]) => k !== '合計（不定期）')
            .reduce((sum, [k, v]) => sum + (v.get(key) || 0), 0)
        );
      });
      incomeSummaryUnregularMonthWork.forEach((category) => {
        category.set('合計',
          Array.from(category.entries())
            .filter(([key, value]) => key !== '合計')
            .reduce((sum, [key, value]) => sum + value, 0)
        );
      });
      incomeSummaryRegularMonthWork.get('合計（定期）')?.forEach((value, key) => {
        incomeSummaryRegularMonthWork.get('合計（定期）')?.set(key,
          Array.from(incomeSummaryRegularMonthWork.entries())
            .filter(([k, v]) => k !== '合計（定期）')
            .reduce((sum, [k, v]) => sum + (v.get(key) || 0), 0)
        );
      });
      incomeSummaryRegularMonthWork.forEach((category) => {
        category.set('合計',
          Array.from(category.entries())
            .filter(([key, value]) => key !== '合計')
            .reduce((sum, [key, value]) => sum + value, 0)
        );
      });
      incomeSummaryMonthWork.get('合計')?.forEach((value, key) => {
        incomeSummaryMonthWork.get('合計')?.set(key,
          (incomeSummaryUnregularMonthWork.get('合計（不定期）')?.get(key) || 0) +
            (incomeSummaryRegularMonthWork.get('合計（定期）')?.get(key) || 0)
        );
      });
      setIncomeSummaryMonth(incomeSummaryMonthWork);
      setIncomeSummaryUnregularMonth(incomeSummaryUnregularMonthWork);
      setIncomeSummaryRegularMonth(incomeSummaryRegularMonthWork);

      transferSummaryMonthWork.get('合計')?.forEach((value, key) => {
        transferSummaryMonthWork.get('合計')?.set(key,
          Array.from(transferSummaryMonthWork.entries())
            .filter(([k, v]) => k !== '合計')
            .reduce((sum, [k, v]) => sum + (v.get(key) || 0), 0)
        );
      });
      transferSummaryMonthWork.forEach((category) => {
        category.set('合計',
          Array.from(category.entries())
            .filter(([key, value]) => key !== '合計')
            .reduce((sum, [key, value]) => sum + value, 0)
        );
      });
      setTransferSummaryMonth(transferSummaryMonthWork);
    }
  }, [
    props.mainData,
    props.fundList,
    props.paymentCategory,
    props.incomeCategory
  ]);

  return (
    <>
      {props.loginUserUid &&
        <Search searchForm={props.searchForm} />
      }

      <h5>月別支出</h5>

      {!paymentSummaryMonth && <p>Loading...</p>}

      {paymentSummaryMonth && paymentSummaryUnregularMonth && paymentSummaryRegularMonth && <>
        <SummaryTable<number>
          summaryObject={[paymentSummaryMonth, paymentSummaryUnregularMonth, paymentSummaryRegularMonth]}
          Cell={(props) => (
            <>
              {props.input > 0 ? <p className="text-danger">{Number(props.input).toLocaleString()}</p> : <p>{Number(props.input).toLocaleString()}</p>}
            </>
          )}
        />
      </>}

      <h5>月別収入</h5>

      {!incomeSummaryMonth && <p>Loading...</p>}

      {incomeSummaryMonth && incomeSummaryUnregularMonth && incomeSummaryRegularMonth && <>
        <SummaryTable<number>
          summaryObject={[incomeSummaryMonth, incomeSummaryUnregularMonth, incomeSummaryRegularMonth]}
          Cell={(props) => (
            <>
              {props.input > 0 ? <p className="text-success">{Number(props.input).toLocaleString()}</p> : <p>{Number(props.input).toLocaleString()}</p>}
            </>
          )}
        />
      </>}

      <h5>月別資金移動</h5>

      {!transferSummaryMonth && <p>Loading...</p>}

      {transferSummaryMonth && <>
        <SummaryTable<number>
          summaryObject={[transferSummaryMonth]}
          Cell={(props) => (
            <>
              {props.input > 0 ? <p className="text-info">{Number(props.input).toLocaleString()}</p> : <p>{Number(props.input).toLocaleString()}</p>}
            </>
          )}
        />
      </>}
    </>
  );
}

export default SummaryMonth;