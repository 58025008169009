import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { database } from '../firebaseConfig';
import { ref, push, runTransaction } from "firebase/database";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { SnapshotVal, IdValue, ShortcutValue } from '../FirebaseType';

import DeleteModal from './DeleteModal';
import ListSortable from './ListSortable';

import { addItem } from '../util/Firebase';

type Inputs = {
  id: string;
  name: string;
  from: string;
  to: string;
  category: string;
  amount: number;
  memo: string;
};

interface SettingShortcutProps {
  list: IdValue<ShortcutValue>[] | undefined;
  loginUserUid: string;
}

function SettingShortcut(props: SettingShortcutProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<IdValue<ShortcutValue>>();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      id: "",
      name: "",
      from: "",
      to: "",
      category: "",
      amount: undefined,
      memo: ""
    }
  });

  const watchId = watch('id');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    runTransaction(ref(database, "/v3/" + props.loginUserUid + "/shortcut"), (post: SnapshotVal<ShortcutValue>) => {
      if(!post) post = {};

      if(watchId) {
        post[watchId].name = getValues('name');
        post[watchId].from = getValues('from');
        post[watchId].to = getValues('to');
        post[watchId].category = getValues('category');
        post[watchId].amount = getValues('amount');
        post[watchId].memo = getValues('memo');
      }
      else {
        const key = push(ref(database, "/v3/" + props.loginUserUid + "/shortcut")).key || '';
        addItem<SnapshotVal<ShortcutValue>>(post, key, {
          name: getValues('name'),
          from: getValues('from'),
          to: getValues('to'),
          category: getValues('category'),
          amount: getValues('amount'),
          memo: getValues('memo'),
          sortNumber: 0
        })
      }

      return post;
    });
    reset();
  }

  const onCancel = () => {
    reset();
  };

  const onEdit = (id: IdValue<ShortcutValue>) => {
    setValue('id', id.id);
    setValue('name', id.value.name);
    setValue('from', id.value.from);
    setValue('to', id.value.to);
    setValue('category', id.value.category);
    setValue('amount', id.value.amount);
    setValue('memo', id.value.memo);
  };

  const onDelete = (id: IdValue<ShortcutValue>) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <h5>ショートカット</h5>
      <hr />
      {props.loginUserUid &&
        <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
          {watchId &&
            <Alert variant="warning">修正中です</Alert>
          }
          <Row className="d-flex align-items-end flex-wrap">
            <Col xs={12} lg={3}>
              <Form.Group>
                <Form.Label>名前</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: '必須項目です'
                  })} />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={3} lg={1}>
              <Form.Group>
                <Form.Label>資金FROM</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.from}
                  {...register('from', {
                    validate: () => !getValues('from') && !getValues('to') ? '資金FROM/資金TOのいずれかは必須です' : undefined
                  })} />
                <Form.Control.Feedback type="invalid">{errors.from?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={3} lg={1}>
              <Form.Group>
                <Form.Label>資金TO</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.to}
                  {...register('to', {
                    validate: () => !getValues('from') && !getValues('to') ? '資金FROM/資金TOのいずれかは必須です' : undefined
                  })} />
                <Form.Control.Feedback type="invalid">{errors.to?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={3} lg={1}>
              <Form.Group>
                <Form.Label>カテゴリ</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.category}
                  {...register('category', {
                    required: '必須項目です'
                  })} />
                <Form.Control.Feedback type="invalid">{errors.category?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={3} lg={1}>
              <Form.Group>
                <Form.Label>金額</Form.Label>
                <Form.Control
                  type="number"
                  isInvalid={!!errors.amount}
                  {...register('amount', {
                    min: {value: 0, message: '0円以上の値を入力してください'}
                  })} />
                <Form.Control.Feedback type="invalid">{errors.amount?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={8} lg={2}>
              <Form.Group>
                <Form.Label>メモ</Form.Label>
                <Form.Control type="text" {...register('memo')} />
              </Form.Group>
            </Col>
            <Col className="pt-2" xs={4} lg={3}>
              <Button type="submit" variant="primary" size="sm" className="mt-lg-4 mt-1 me-1">登録</Button>
              <Button variant="secondary" size="sm" className="mt-lg-4 mt-1 me-1" onClick={onCancel}>キャンセル</Button>
            </Col>
          </Row>
        </Form>
      }

      {!props.list && <p>Loading...</p>}

      {props.list &&
        <ListSortable<ShortcutValue>
          list={props.list}
          databasePath={"/v3/" + props.loginUserUid + "/shortcut"}
          columnWidthLg={[1, 2, 1, 1, 1, 1, 2, 3]}
          columnWidthXs={[2, 10, 3, 3, 3, 3, 8, 4]}
          columnJSX={[
            (item) => <>{item.value.name}</>,
            (item) => <>{item.value.from}</>,
            (item) => <>{item.value.to}</>,
            (item) => <>{item.value.category}</>,
            (item) => <>{Number(item.value.amount).toLocaleString()}</>,
            (item) => <>{item.value.memo}</>
          ]}
          onEdit={onEdit}
          editingId={watchId}
          onDelete={onDelete}
        />
      }

      <DeleteModal<ShortcutValue>
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        databasePath={"/v3/" + props.loginUserUid + "/shortcut"}
      />
    </>
  );
}

export default SettingShortcut;