import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { IdValue, MainDataValue, FundListValue } from '../FirebaseType';

import Item from './Item';

import { hyphenStringToJSX } from '../util/Date';
import { judgeType } from '../util/Firebase';

interface DateItemProps {
  date: string;
  items: IdValue<MainDataValue>[];
  fundList: IdValue<FundListValue>[] | undefined;
  key: string;
  registerForm: any;
  loginUserUid: string;
  onAddGroupingId: (id: IdValue<MainDataValue>) => void;
  groupingId: IdValue<MainDataValue>[];
  onDelete: (id: IdValue<MainDataValue>) => void;
}

type PaymentIncomeSummary = {
  paymentSummary: number;
  incomeSummary: number;
};

function DateItem(props: DateItemProps) {
  const [paymentIncomeSummary, setPaymentIncomeSummary] = useState<PaymentIncomeSummary>();

  useEffect(() => {
    const result = props.items
      .filter((item) => !item.value.isDisabled)
      .filter((item) => !item.value.isAdvance)
      .reduce((sum: PaymentIncomeSummary, data: IdValue<MainDataValue>) => {
        switch(judgeType(data)) {
          case 'payment':
            sum.paymentSummary += data.value.amount;
            break;
          case 'income':
            sum.incomeSummary += data.value.amount;
            break;
          default:
            break;
        }
        return sum;
      }, {paymentSummary: 0, incomeSummary: 0})
    setPaymentIncomeSummary(result);
  }, [props.items]);

  return (
    <>
      <Row className="bg-secondary text-white py-2">
        <Col>{hyphenStringToJSX(props.date)}</Col>
        <Col className="d-flex justify-content-end flex-wrap">
          <div className="px-1">[支払] {Number(paymentIncomeSummary?.paymentSummary).toLocaleString()}</div>
          <div className="px-1">[収入] {Number(paymentIncomeSummary?.incomeSummary).toLocaleString()}</div>
        </Col>
      </Row>
      {props.items.map((keyVal) => {
        return <Item
          item={keyVal}
          fundList={props.fundList}
          key={keyVal.id}
          registerForm={props.registerForm}
          loginUserUid={props.loginUserUid}
          onAddGroupingId={props.onAddGroupingId}
          groupingId={props.groupingId}
          onDelete={props.onDelete}
        />;
      })}
    </>
  );
}

export default React.memo(DateItem);