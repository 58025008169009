import { useState } from 'react';

import { functions, storage } from '../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { ref, getDownloadURL } from 'firebase/storage';

export const useDownloadCSV = () => {
    const [loading, setLoading] = useState<boolean>(false);
    
    const downloadCSV: () => void = () => {
        setLoading(true);
        const createCSV = httpsCallable(functions, "createCSV");
        createCSV().then((result: any) => {
            getDownloadURL(ref(storage, result?.data?.objectPath)).then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.download = result?.data?.localFileName;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                };
                xhr.open('GET', url);
                xhr.send();

                setLoading(false);
            });
        });
    };

    return {
        csvLoading: loading,
        downloadCSV: downloadCSV
    };
};