import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { database } from '../firebaseConfig';
import { ref, push, runTransaction } from "firebase/database";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { GoCheck } from 'react-icons/go';

import { SnapshotVal, IdValue, IncomeCategoryValue } from '../FirebaseType';

import DeleteModal from './DeleteModal';
import ListSortable from './ListSortable';

import { addItem } from '../util/Firebase';

type Inputs = {
  id: string;
  name: string;
  regular: boolean;
};

interface SettingIncomeCategoryProps {
  list: IdValue<IncomeCategoryValue>[] | undefined;
  loginUserUid: string;
}

function SettingIncomeCategory(props: SettingIncomeCategoryProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<IdValue<IncomeCategoryValue>>();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      id: "",
      name: "",
      regular: false
    }
  });

  const watchId = watch('id');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    runTransaction(ref(database, "/v3/" + props.loginUserUid + "/incomeCategory"), (post: SnapshotVal<IncomeCategoryValue>) => {
      if(!post) post = {};

      if(watchId) {
        post[watchId].name = getValues('name');
        post[watchId].regular = getValues('regular');
      }
      else {
        const key = push(ref(database, "/v3/" + props.loginUserUid + "/incomeCategory")).key || '';
        addItem<SnapshotVal<IncomeCategoryValue>>(post, key, {
          name: getValues('name'),
          regular: getValues('regular'),
          sortNumber: 0
        })
      }

      return post;
    });
    reset();
  }

  const onCancel = () => {
    reset();
  };

  const onEdit = (id: IdValue<IncomeCategoryValue>) => {
    setValue('id', id.id);
    setValue('name', id.value.name);
    setValue('regular', id.value.regular);
  };

  const onDelete = (id: IdValue<IncomeCategoryValue>) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <h5>収入カテゴリ</h5>
      <hr />
      {props.loginUserUid &&
        <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
          {watchId &&
            <Alert variant="warning">修正中です</Alert>
          }
          <Row className="d-flex align-items-end flex-wrap">
            <Col xs={5}>
              <Form.Group>
                <Form.Label>名前</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: '必須項目です'
                  })} />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group>
                <Form.Label>定期</Form.Label>
                <Form.Check type="switch" className="switch-custom" {...register('regular')} />
              </Form.Group>
            </Col>
            <Col className="pt-2 z-3" xs={4}>
              <Button type="submit" variant="primary" size="sm" className="mt-lg-4 mt-1 me-1">登録</Button>
              <Button variant="secondary" size="sm" className="mt-lg-4 mt-1 me-1" onClick={onCancel}>キャンセル</Button>
            </Col>
          </Row>
        </Form>
      }

      {!props.list && <p>Loading...</p>}

      {props.list &&
        <ListSortable<IncomeCategoryValue>
          list={props.list}
          databasePath={"/v3/" + props.loginUserUid + "/incomeCategory"}
          columnWidthLg={[1, 4, 3, 4]}
          columnWidthXs={[1, 4, 3, 4]}
          columnJSX={[
            (item) => <>{item.value.name}</>,
            (item) => <>{item.value.regular ? <GoCheck /> : null}</>
          ]}
          onEdit={onEdit}
          editingId={watchId}
          onDelete={onDelete}
        />
      }

      <DeleteModal<IncomeCategoryValue>
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        databasePath={"/v3/" + props.loginUserUid + "/incomeCategory"}
      />
    </>
  );
}

export default SettingIncomeCategory;