import React from 'react';
import { useNavigate } from 'react-router-dom';

import { database } from '../firebaseConfig';
import { ref, onValue, query, orderByChild, startAt, endAt, update } from "firebase/database";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

import { SnapshotVal, IdValue, MainDataValue, FundListValue } from '../FirebaseType';

import { judgeCheckTarget, judgeType } from '../util/Firebase';

import {
  FaEdit,
  FaTrash,
  FaCheckCircle,
  FaMinusCircle,
  FaLockOpen,
  FaLock,
  FaBalanceScaleLeft,
  FaBalanceScale
} from 'react-icons/fa';

interface ItemProps {
  item: IdValue<MainDataValue>;
  fundList: IdValue<FundListValue>[] | undefined;
  key: string;
  registerForm: any;
  loginUserUid: string;
  onAddGroupingId: (id: IdValue<MainDataValue>) => void;
  groupingId: IdValue<MainDataValue>[];
  onDelete: (id: IdValue<MainDataValue>) => void;
}

function Item(props: ItemProps) {
  const navigate = useNavigate();

  return (
    <Row className={"border-bottom py-2" + (
      props.item.value.isDisabled ? " bg-light" : ""
    )}>
      <Col lg={5}>
        <Row className={(
          props.item.value.isDisabled ? "text-secondary" : ""
        ) + (
          props.item.value.sortId.split(".")[2] ==="0" ? " border-start border-secondary" : ""
        ) + (
          props.item.value.sortId.split(".")[2] === "1" ? " border-start" : ""
        )}>
          <Col xs={5}>{(() => {
            switch(judgeType(props.item)) {
              case 'payment':
                return <>[支払]<br />{props.item.value.from}</>;
              case 'income':
                return <>[収入]<br />{props.item.value.to}</>;
              case 'transfer':
                return <>[資金移動]<br />{props.item.value.from}→{props.item.value.to}</>;
              case 'evaluation':
                return <>[資金評価]<br />{props.item.value.from}</>;
              default:
                return <></>;
            }
          })()}</Col>
          <Col xs={4}>{props.item.value.category}</Col>
          <Col xs={3} className="text-end">{Number(props.item.value.amount).toLocaleString()}</Col>
        </Row>
      </Col>
      <Col lg={7}>
        <Row>
          <Col xs={9}>
            <Badge pill bg="secondary">{props.item.value.isRegular ? '定期' : '不定期'}</Badge>
            {props.item.value.isAdvance && (props.item.value.isReimbursed ?
              <Badge pill bg="secondary">立替精算済</Badge> :
              <Badge pill bg="warning">立替未精算</Badge>
            )}
            {judgeCheckTarget(props.item, props.fundList) && (props.item.value.sortId.split(".")[2] ?
              <Badge pill bg="success">チェック済</Badge> :
              <Badge pill bg="danger">未チェック</Badge>
            )}
            <Badge pill bg="light" text="dark">{props.item.value.dataSource}</Badge>
          </Col>
          <Col xs={3}>
            <span title="修正">
              {props.item.value.sortId.split(".")[2] ?
                <FaEdit size="1.2em" color="gray" className="me-1" /> :
                <FaEdit size="1.2em" color={props.item.id === props.registerForm.getValues('id') ? "red" : ""} className="me-1 pointer" onClick={() => {
                  navigate('/register', {state: props.item, replace: true});
                }} />
              }
            </span>
            <span title="削除">
              {props.item.value.sortId.split(".")[2] ?
                <FaTrash size="1.2em" color="gray" className="me-1" /> :
                <FaTrash size="1.2em" className="me-1 pointer" onClick={() => {
                  props.onDelete(props.item);
                }} />
              }
            </span>
            {props.item.value.isDisabled ?
              <span title="有効化"><FaCheckCircle size="1.2em" className="d-none d-lg-inline me-1 pointer" onClick={() => {
                update(ref(database, "/v3/" + props.loginUserUid + "/mainData/" + props.item.id), {isDisabled: false});
              }} /></span> :
              <span title="無効化"><FaMinusCircle size="1.2em" className="d-none d-lg-inline me-1 pointer" onClick={() => {
                update(ref(database, "/v3/" + props.loginUserUid + "/mainData/" + props.item.id), {isDisabled: true});
              }} /></span>
            }
            {props.item.value.sortId.split(".")[2] ?
              <span title="グループ化解除"><FaLockOpen size="1.2em" className="d-none d-lg-inline me-1 pointer" onClick={() => {
                onValue(query(
                  ref(database, "/v3/" + props.loginUserUid + "/mainData"),
                  orderByChild("sortId"),
                  startAt(props.item.value.sortId.split(".").slice(0, 2).join(".")),
                  endAt(props.item.value.sortId.split(".").slice(0, 2).join(".") + "\uf8ff")
                ),(snapshot) => {
                  const updates: SnapshotVal<string> = {};
                  snapshot.forEach((i) => {
                    updates[i.key + "/sortId"] = i.val().date + "." + i.key;
                  });
                  update(ref(database, "/v3/" + props.loginUserUid + "/mainData"), updates);
                }, {
                  onlyOnce: true
                });
              }} /></span> :
              <span title="グループ化"><FaLock size="1.2em"
                color={props.groupingId.find((id) => id.id === props.item.id) ? "red" : ""}
                className="d-none d-lg-inline me-1 pointer"
                onClick={() => {props.onAddGroupingId(props.item)}} /></span>
            }
            {props.item.value.isAdvance && (props.item.value.isReimbursed ?
              <span title="立替精算解除"><FaBalanceScaleLeft size="1.2em" className="me-1 pointer" onClick={() => {
                update(ref(database, "/v3/" + props.loginUserUid + "/mainData/" + props.item.id), {isReimbursed: false});
              }} /></span> :
              <span title="立替精算"><FaBalanceScale size="1.2em" className="me-1 pointer" onClick={() => {
                update(ref(database, "/v3/" + props.loginUserUid + "/mainData/" + props.item.id), {isReimbursed: true});
              }} /></span>
            )}
          </Col>
        </Row>
        <Row className={
          props.item.value.isDisabled ? "text-secondary" : ""
        }>
          <Col>{props.item.value.memo}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default React.memo(Item);