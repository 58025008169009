import { database } from '../firebaseConfig';
import { ref, runTransaction } from "firebase/database";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SnapshotVal, IdValue, SettingListValueAbstract } from '../FirebaseType';

import { deleteItem } from '../util/Firebase';

interface DeleteModalProps<T extends SettingListValueAbstract> {
  showDeleteModal: boolean;
  setShowDeleteModal: (show: boolean) => void;
  deleteId: IdValue<T> | undefined;
  setDeleteId: (id: IdValue<T> | undefined) => void;
  databasePath: string;
}

function DeleteModal<T extends SettingListValueAbstract>(props: DeleteModalProps<T>) {
  const onDeleteCancel = () => {
    props.setShowDeleteModal(false);
    props.setDeleteId(undefined);
  }

  return (
    <>
      <Modal show={props.showDeleteModal} onHide={()=>onDeleteCancel()}>
        <Modal.Header closeButton>
          <Modal.Title>削除</Modal.Title>
        </Modal.Header>
        <Modal.Body>「{props.deleteId?.value.name}」を削除します</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>onDeleteCancel()}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={()=>{
            runTransaction(ref(database, props.databasePath), (post: SnapshotVal<T>) => {
              if(post) {
                deleteItem<SnapshotVal<T>>(post, props.deleteId?.id || '');
              }
              return post;
            });
            onDeleteCancel();
          }}>
            削除
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;