import { useState, useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';

import { auth, database } from './firebaseConfig';
import { signInWithPopup, signOut, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { ref, onValue, push, set, query, orderByChild, startAt, endAt, off } from 'firebase/database';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import {
  IdValue,
  MainDataValue,
  FundListValue,
  PaymentCategoryValue,
  IncomeCategoryValue,
  TagValue,
  ShortcutValue,
  SummaryList,
  HistoryList
} from './FirebaseType';

import { useDownloadCSV } from './common/customHooks';
import Register from './register/Register';
import List from './list/List';
import SummaryMonth from './summary/SummaryMonth';
import SummaryMonthCompare from './summary/SummaryMonthCompare';
import SummaryAdvance from './summary/SummaryAdvance';
import History from './history/History';
import SettingFundList from './setting/SettingFundList';
import SettingPaymentCategory from './setting/SettingPaymentCategory';
import SettingIncomeCategory from './setting/SettingIncomeCategory';
import SettingTag from './setting/SettingTag';
import SettingShortcut from './setting/SettingShortcut';
import Upload from './upload/Upload';

import { dateToHyphenString, dateToMonthHyphenString } from './util/Date';
import { objectToArray, judgeCheckTarget, convertRegisterFormInputsToMainDataValue } from './util/Firebase';

const provider = new GoogleAuthProvider();

export type RegisterFormInputs = {
  id: string;
  date: string;
  type: string;
  from: string;
  to: string;
  evaluationFromTo: string;
  paymentCategory: string;
  incomeCategory: string;
  amount: number;
  memo: string;
  memoWithoutTag: string;
  memoTagOnly: string[];
  isRegular: boolean;
  isAdvance: boolean;
  isReimbursed: boolean;
  isDisabled: boolean;
  sortId: string;
  dataSource: string;
};

type SearchFormInputs = {
  month: string;
  search: string;
  displayIsDisabled: boolean;
  displayOnlyIsRegular: boolean;
  displayOnlyIsAdvance: boolean;
  displayOnlyIsUncheck: boolean;
}

type LoginUser = {
  displayName: string | null;
  email: string | null;
  uid: string | null;
};

function App() {
  const containerRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [loginUser, setLoginUser] = useState<LoginUser>();
  const [mainData, setMainData] = useState<IdValue<MainDataValue>[]>();
  const [filteredMainData, setFilteredMainData] = useState<IdValue<MainDataValue>[]>();
  const [fundList, setFundList] = useState<IdValue<FundListValue>[]>();
  const [paymentCategory, setPaymentCategory] = useState<IdValue<PaymentCategoryValue>[]>();
  const [incomeCategory, setIncomeCategory] = useState<IdValue<IncomeCategoryValue>[]>();
  const [tag, setTag] = useState<IdValue<TagValue>[]>();
  const [shortcut, setShortcut] = useState<IdValue<ShortcutValue>[]>();
  const [summaryList, setSummaryList] = useState<SummaryList>();
  const [historyList, setHistoryList] = useState<HistoryList>();

  const {csvLoading, downloadCSV} = useDownloadCSV();

  const registerForm = useForm<RegisterFormInputs>({
    defaultValues: {
      id: "",
      date: dateToHyphenString(new Date()),
      type: "payment",
      from: "",
      to: "",
      evaluationFromTo: "",
      paymentCategory: "",
      incomeCategory: "",
      amount: undefined,
      memo: "",
      memoWithoutTag: "",
      memoTagOnly: [],
      isRegular: false,
      isAdvance: false,
      isReimbursed: false,
      isDisabled: false,
      sortId: "",
      dataSource: "kakeibo"
    }
  });

  const onSubmit: SubmitHandler<RegisterFormInputs> = (data) => {
    if(loginUser) {
      let postData: MainDataValue = convertRegisterFormInputsToMainDataValue(data);
      let key = data.id;
      if(!key) {
        key = push(ref(database, "/v3/" + loginUser.uid + "/mainData")).key || '';
      }
      postData.sortId = postData.date + "." + key;
      set(ref(database, "/v3/" + loginUser.uid + "/mainData/" + key), postData)
      registerForm.reset();
    }
  }

  const searchForm = useForm<SearchFormInputs>({
    defaultValues: {
      month: dateToMonthHyphenString(new Date()),
      search: "",
      displayIsDisabled: false,
      displayOnlyIsRegular: false,
      displayOnlyIsAdvance: false,
      displayOnlyIsUncheck: false
    }
  });

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if(user) {
        setLoginUser({
          displayName: user.displayName,
          email: user.email,
          uid: user.uid
        });
      }
      else {
        setLoginUser(undefined);
      }
    })
  }, []);

  const watchMonth = searchForm.watch('month');
  useEffect(() => {
    if(loginUser) {
      off(ref(database, "/v3/" + loginUser.uid + "/mainData"));
      setFilteredMainData(undefined);

      return onValue(query(
        ref(database, "/v3/" + loginUser.uid + "/mainData"),
        orderByChild("sortId"),
        startAt(watchMonth),
        endAt(watchMonth + "\uf8ff")
      ),(snapshot) => {
        if(snapshot.exists()) {
          setMainData(
            (objectToArray(snapshot) as IdValue<MainDataValue>[])
              .sort((f, s) => {
                const f1 = f.value.sortId.split(".")[0] + "." + f.value.sortId.split(".")[1];
                const f2 = f.value.sortId.split(".")[2];
                const s1 = s.value.sortId.split(".")[0] + "." + s.value.sortId.split(".")[1];
                const s2 = s.value.sortId.split(".")[2];
                if(f1 > s1) {return -1;}
                else if(f1 < s1) {return 1;}
                else {
                  if(f2 < s2) {return -1;}
                  else {return 1;}
                }
              })
          );
        }
        else {
          setMainData([]);
        }
      });
    }
    else {
      setMainData(undefined);
    }
  }, [loginUser, watchMonth]);

  const watchSearch = searchForm.watch('search');
  const watchDisplayIsDisabled = searchForm.watch('displayIsDisabled');
  const watchDisplayOnlyIsRegular = searchForm.watch('displayOnlyIsRegular');
  const watchDisplayOnlyIsAdvance = searchForm.watch('displayOnlyIsAdvance');
  const watchDisplayOnlyIsUncheck = searchForm.watch('displayOnlyIsUncheck');
  useEffect(() => {
    if(mainData) {
      setFilteredMainData(mainData
        .filter((idVal) => (JSON.stringify(idVal.value).match(watchSearch)))
        .filter((idVal) => (!idVal.value.isDisabled || watchDisplayIsDisabled))
        .filter((idVal) => (idVal.value.isRegular || !watchDisplayOnlyIsRegular))
        .filter((idVal) => (idVal.value.isAdvance || !watchDisplayOnlyIsAdvance))
        .filter((idVal) => (
          (judgeCheckTarget(idVal, fundList) && (!idVal.value.sortId.split(".")[2])) || !watchDisplayOnlyIsUncheck
        ))
      );
    }
    else {
      setFilteredMainData(undefined);
    }
  }, [
    mainData,
    fundList,
    watchSearch,
    watchDisplayIsDisabled,
    watchDisplayOnlyIsRegular,
    watchDisplayOnlyIsAdvance,
    watchDisplayOnlyIsUncheck
  ]);

  useEffect(() => {
    if(loginUser) {
      return onValue(query(ref(database, "/v3/" + loginUser.uid + "/fundList"), orderByChild("sortNumber")), (snapshot) => {
        if(snapshot.exists()) {
          setFundList(objectToArray(snapshot) as IdValue<FundListValue>[]);
        }
      });
    }
    else {
      setFundList(undefined);
    }
  }, [loginUser]);

  useEffect(() => {
    if(loginUser) {
      return onValue(query(ref(database, "/v3/" + loginUser.uid + "/paymentCategory"), orderByChild("sortNumber")), (snapshot) => {
        if(snapshot.exists()) {
          setPaymentCategory(objectToArray(snapshot) as IdValue<PaymentCategoryValue>[]);
        }
      });
    }
    else {
      setPaymentCategory(undefined);
    }
  }, [loginUser]);

  useEffect(() => {
    if(loginUser) {
      return onValue(query(ref(database, "/v3/" + loginUser.uid + "/incomeCategory"), orderByChild("sortNumber")), (snapshot) => {
        if(snapshot.exists()) {
          setIncomeCategory(objectToArray(snapshot) as IdValue<IncomeCategoryValue>[]);
        }
      });
    }
    else {
      setIncomeCategory(undefined);
    }
  }, [loginUser]);

  useEffect(() => {
    if(loginUser) {
      return onValue(query(ref(database, "/v3/" + loginUser.uid + "/tag"), orderByChild("sortNumber")), (snapshot) => {
        if(snapshot.exists()) {
          setTag(objectToArray(snapshot) as IdValue<TagValue>[]);
        }
      });
    }
    else {
      setTag(undefined);
    }
  }, [loginUser]);

  useEffect(() => {
    if(loginUser) {
      return onValue(query(ref(database, "/v3/" + loginUser.uid + "/shortcut"), orderByChild("sortNumber")), (snapshot) => {
        if(snapshot.exists()) {
          setShortcut(objectToArray(snapshot) as IdValue<ShortcutValue>[]);
        }
      });
    }
    else {
      setShortcut(undefined);
    }
  }, [loginUser]);

  useEffect(() => {
    if(loginUser) {
      return onValue(ref(database, "/v3/" + loginUser.uid + "/summaryList"), (snapshot) => {
        if(snapshot.exists()) {
          setSummaryList({
            advanceSummaryList: new Map(snapshot.val().advanceSummaryList.map(
              (i: any)=>[i[0], new Map(i[1])]
            )),
            paymentSummaryList: {
              paymentSummaryMonthList: new Map(snapshot.val().paymentSummaryList.paymentSummaryMonthList.map(
                (i: any)=>[i[0], new Map(i[1])]
              )),
              paymentSummaryUnregularMonthList: new Map(snapshot.val().paymentSummaryList.paymentSummaryUnregularMonthList.map(
                (i: any)=>[i[0], new Map(i[1])]
              )),
              paymentSummaryRegularMonthList: new Map(snapshot.val().paymentSummaryList.paymentSummaryRegularMonthList.map(
                (i: any)=>[i[0], new Map(i[1])]
              ))
            },
            incomeSummaryList: {
              incomeSummaryMonthList: new Map(snapshot.val().incomeSummaryList.incomeSummaryMonthList.map(
                (i: any)=>[i[0], new Map(i[1])]
              )),
              incomeSummaryUnregularMonthList: new Map(snapshot.val().incomeSummaryList.incomeSummaryUnregularMonthList.map(
                (i: any)=>[i[0], new Map(i[1])]
              )),
              incomeSummaryRegularMonthList: new Map(snapshot.val().incomeSummaryList.incomeSummaryRegularMonthList.map(
                (i: any)=>[i[0], new Map(i[1])]
              ))
            }
          });
        }
      });
    }
    else {
      setSummaryList(undefined);
    }
  }, [loginUser]);

  useEffect(() => {
    if(loginUser) {
      return onValue(ref(database, "/v3/" + loginUser.uid + "/historyList"), (snapshot) => {
        if(snapshot.exists()) {
          setHistoryList(new Map(snapshot.val().map(
              (i: any)=>[i[0], new Map(i[1])]
          )));
        }
      });
    }
    else {
      setHistoryList(undefined);
    }
  }, [loginUser]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const closeExpanded = () => {
    setExpanded(false);
  };

  const login = () => {
    signInWithPopup(auth ,provider)
    .then((result) => {
      setExpanded(false);
    }).catch((error) => {
      console.log(error.code + " " + error.message);
    });
  };
  
  const logout = () => {
    signOut(auth).then(() => {
      setExpanded(false);
    }).catch((error) => {
      console.log(error.code + " " + error.message);
    });
  };

  return (
    <BrowserRouter>
      <Container ref={containerRef}>
        <Navbar bg="light" expand="lg" className="mb-3" expanded={expanded}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleExpanded} />
          <Navbar.Collapse id="basic-navbar-nav" className="">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/register" replace onClick={closeExpanded}>登録</Nav.Link>
              <Nav.Link as={Link} to="/list" replace onClick={closeExpanded}>一覧</Nav.Link>
              <NavDropdown title="集計" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} replace to="/summary-month" onClick={closeExpanded}>月別</NavDropdown.Item>
                <NavDropdown.Item as={Link} replace to="/summary-month-compare" onClick={closeExpanded}>月比較</NavDropdown.Item>
                <NavDropdown.Item as={Link} replace to="/summary-advance" onClick={closeExpanded}>立替</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} replace to="/history" onClick={closeExpanded}>資金</Nav.Link>
              <NavDropdown title="設定" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} replace to="/setting-fund-list" onClick={closeExpanded}>資金</NavDropdown.Item>
                <NavDropdown.Item as={Link} replace to="/setting-payment-category" onClick={closeExpanded}>支払カテゴリ</NavDropdown.Item>
                <NavDropdown.Item as={Link} replace to="/setting-income-category" onClick={closeExpanded}>収入カテゴリ</NavDropdown.Item>
                <NavDropdown.Item as={Link} replace to="/setting-tag" onClick={closeExpanded}>タグ</NavDropdown.Item>
                <NavDropdown.Item as={Link} replace to="/setting-shortcut" onClick={closeExpanded}>ショートカット</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} replace to="/upload" className="d-none d-lg-inline" onClick={closeExpanded}>アップロード</Nav.Link>
              <Nav.Link className="d-none d-lg-inline" disabled={!loginUser?.uid || csvLoading} onClick={downloadCSV}>
                {csvLoading ?
                  <>
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    CSVダウンロード中...
                  </> :
                  <>CSVダウンロード</>
                }
              </Nav.Link>
            </Nav>
            <Navbar.Text>
              {loginUser?.displayName}
              {!loginUser && <Button variant="secondary" className="me-3" onClick={login}>ログイン</Button>}
              {loginUser && <Button variant="outline-secondary" className="mx-3" onClick={logout}>ログアウト</Button>}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <Routes>
          <Route path="/*" element={<Navigate to="/register" />} />
          <Route path="/register" element={<Register
            containerRef={containerRef}
            fundList={fundList}
            paymentCategory={paymentCategory}
            incomeCategory={incomeCategory}
            tag={tag}
            shortcut={shortcut}
            registerForm={registerForm}
            onSubmit={onSubmit}
          />} />
          <Route path="/list" element={<List
            mainData={filteredMainData}
            fundList={fundList}
            searchForm={searchForm}
            registerForm={registerForm}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/summary-month" element={<SummaryMonth
            mainData={filteredMainData}
            fundList={fundList}
            paymentCategory={paymentCategory}
            incomeCategory={incomeCategory}
            searchForm={searchForm}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/summary-month-compare" element={<SummaryMonthCompare
            summaryList={summaryList}
          />} />
          <Route path="/summary-advance" element={<SummaryAdvance
            summaryList={summaryList}
          />} />
          <Route path="/history" element={<History
            historyList={historyList}
          />} />
          <Route path="/setting-fund-list" element={<SettingFundList
            list={fundList}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/setting-payment-category" element={<SettingPaymentCategory
            list={paymentCategory}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/setting-income-category" element={<SettingIncomeCategory
            list={incomeCategory}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/setting-tag" element={<SettingTag
            list={tag}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/setting-shortcut" element={<SettingShortcut
            list={shortcut}
            loginUserUid={loginUser?.uid || ''}
          />} />
          <Route path="/upload" element={<Upload
            loginUserUid={loginUser?.uid || ''}
          />} />
        </Routes>
        <footer className="footer mt-3">
          <p>COPYRIGHT (C)SHIGEMA. ALL RIGHTS RESERVED.</p>
        </footer>
      </Container>
    </BrowserRouter>
  );
}

export default App;
