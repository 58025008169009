import { DataSnapshot } from "firebase/database";

import { RegisterFormInputs } from '../App';
import { SnapshotVal, IdValue, MainDataValue, SettingListValueAbstract, FundListValue } from '../FirebaseType';

export const objectToArray = <T,>(object: DataSnapshot): IdValue<T>[] => {
  const result: IdValue<T>[] = [];
  object.forEach((v: DataSnapshot) => {
      result.push({
          id: v.key || '',
          value: v.val()
      });
  })
  return result;
};

export const judgeType = (mainData: IdValue<MainDataValue>): string => {
  if(mainData.value.from !== "" && mainData.value.to === "") {
    return 'payment';
  }
  else if(mainData.value.from === "" && mainData.value.to !== "") {
    return 'income';
  }
  else if(mainData.value.category === "資金移動") {
    return 'transfer';
  }
  else if(mainData.value.category === "資金評価") {
    return 'evaluation';
  }

  return '';
}

export const judgeCheckTarget = (mainData: IdValue<MainDataValue>, fundList: IdValue<FundListValue>[] | undefined): boolean => {
  if(!fundList) return false;
  if(mainData.value.category === '資金評価') return false;

  const fromCheck = fundList.find((f) => f.value.name === mainData.value.from)?.value.check || false;
  const toCheck = fundList.find((f) => f.value.name === mainData.value.to)?.value.check || false;
  return (!mainData.value.isRegular && (fromCheck || toCheck))
    || (mainData.value.isRegular && fromCheck && toCheck );
}

export const convertRegisterFormInputsToMainDataValue = (registerFormInputs: RegisterFormInputs): MainDataValue => {
  let mainDataValue: MainDataValue = {
    date: registerFormInputs.date,
    from: "",
    to: "",
    category: "",
    amount: registerFormInputs.amount,
    memo: registerFormInputs.memo,
    isRegular: registerFormInputs.isRegular,
    isAdvance: registerFormInputs.isAdvance,
    isReimbursed: registerFormInputs.isReimbursed,
    isDisabled: registerFormInputs.isDisabled,
    sortId: registerFormInputs.sortId,
    dataSource: registerFormInputs.dataSource
  };
  switch(registerFormInputs.type) {
    case 'payment':
      mainDataValue.from = registerFormInputs.from;
      mainDataValue.category = registerFormInputs.paymentCategory;
      break;
    case 'income':
      mainDataValue.to = registerFormInputs.to;
      mainDataValue.category = registerFormInputs.incomeCategory;
      break;
    case 'transfer':
      mainDataValue.from = registerFormInputs.from;
      mainDataValue.to = registerFormInputs.to;
      mainDataValue.category = '資金移動';
      break;
    case 'evaluation':
      mainDataValue.from = registerFormInputs.evaluationFromTo;
      mainDataValue.to = registerFormInputs.evaluationFromTo;
      mainDataValue.category = '資金評価';
      break;
  }
  return mainDataValue;
}

export function addItem<T extends SnapshotVal<SettingListValueAbstract>>(list: T, newKey: keyof T, newValue: any) {
  Object.entries(list).forEach(([key, value]) => {
    if(value.sortNumber >= newValue.sortNumber) {
      value.sortNumber++;
    }
  })
  list[newKey] = newValue;
}

export function deleteItem<T extends SnapshotVal<SettingListValueAbstract>>(list: T, oldKey: keyof T) {
  const oldSortNumber = list[oldKey].sortNumber;
  delete list[oldKey];
  Object.entries(list).forEach(([key, value]) => {
    if(value.sortNumber > oldSortNumber) {
      value.sortNumber--;
    }
  })
}