import React, { useState } from 'react';

import { database } from '../firebaseConfig';
import { ref, update, remove } from "firebase/database";

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';

import { SnapshotVal, IdValue, MainDataValue, FundListValue } from '../FirebaseType';

import Search from '../common/Search';
import DateItem from './DateItem';

import { hyphenStringToJSX } from '../util/Date';

interface GroupingOffcanvasProps {
  show: boolean;
  onHide: () => void;
  onGroupGroupingId: () => void;
  groupingId: IdValue<MainDataValue>[];
}

function GroupingOffcanvas(props: GroupingOffcanvasProps) {
  return (
    <Offcanvas
      show={props.groupingId.length > 0 && props.show}
      onHide={props.onHide}
      scroll={true}
      backdrop={false}
      placement='top'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>グループ化</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {props.groupingId.map((id, index) => (
          <div>
            {index === 0 ? "[メイン]" : " - [サブ]"} 日付：{hyphenStringToJSX(id.value.date)}, カテゴリ：{id.value.category}, 金額：{Number(id.value.amount).toLocaleString()}
          </div>
        ))}
        <Button onClick={props.onGroupGroupingId}>実行</Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

type ReducedMainData = {
  date: string;
  mainData: IdValue<MainDataValue>[];
};

interface ListProps {
  mainData: IdValue<MainDataValue>[] | undefined;
  fundList: IdValue<FundListValue>[] | undefined;
  searchForm: any;
  registerForm: any;
  loginUserUid: string;
}

function List(props: ListProps) {
  const [showGroupingModal, setShowGroupingModal] = useState<boolean>(false);
  const [groupingId, setGroupingId] = useState<IdValue<MainDataValue>[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<IdValue<MainDataValue>>();

  const addGroupingId = (id: IdValue<MainDataValue>) => {
    setShowGroupingModal(true);
    if(groupingId.find((i) => i.id === id.id)) {
      setGroupingId(groupingId.filter((i) => i.id !== id.id));
    }
    else {
      setGroupingId([...groupingId, id]);
    }
  };

  const groupGroupingId = () => {
    const updates: SnapshotVal<string> = {};
    groupingId.forEach((i, index) => {
      updates[i.id + "/sortId"] = groupingId[0].value.sortId + (index === 0 ? ".0" : ".1");
    });
    update(ref(database, "/v3/" + props.loginUserUid + "/mainData"), updates);
    setShowGroupingModal(false);
    setGroupingId([]);
  };

  const resetGroupingId = () => {
    setShowGroupingModal(false);
    setGroupingId([]);
  };

  const onDelete = (id: IdValue<MainDataValue>) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
    setDeleteId(undefined);
  }

  return (
    <>
      {props.loginUserUid &&
        <Search searchForm = {props.searchForm} />
      }

      {!props.mainData && <p>Loading...</p>}

      {props.mainData &&
        props.mainData.reduce((result: ReducedMainData[], idVal: IdValue<MainDataValue>) => {
          const element = result.find((item) => item.date === idVal.value.sortId.split(".")[0]);
          if(element) {
            element.mainData.push(idVal);
          }
          else {
            result.push({date: idVal.value.date, mainData: [idVal]});
          }
          return result;
        }, [] as ReducedMainData[])
        .map((item) => (
          <DateItem
            date={item.date}
            items={item.mainData}
            fundList={props.fundList}
            key={item.date}
            registerForm={props.registerForm}
            loginUserUid={props.loginUserUid}
            onAddGroupingId={addGroupingId}
            groupingId={groupingId}
            onDelete={onDelete}
          />
        ))
      }

      <GroupingOffcanvas
        show={showGroupingModal}
        onHide={resetGroupingId}
        onGroupGroupingId={groupGroupingId}
        groupingId={groupingId}
      />

      <Modal show={showDeleteModal} onHide={()=>onDeleteCancel()}>
        <Modal.Header closeButton>
          <Modal.Title>削除</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          「日付：{deleteId ? hyphenStringToJSX(deleteId?.value.date) : ""}, カテゴリ：{deleteId?.value.category}, 金額：{Number(deleteId?.value.amount).toLocaleString()}」を削除します
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>onDeleteCancel()}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={()=>{
            remove(ref(database, "/v3/" + props.loginUserUid + "/mainData/" + deleteId?.id));
            onDeleteCancel();
          }}>
            削除
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default React.memo(List);