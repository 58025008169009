import Table from 'react-bootstrap/Table';

interface SummaryTableProps<T> {
  summaryObject: Map<string, Map<string, T>>[];
  Cell: React.FC<{input: T;}>;
}

function SummaryTable<T>(props: SummaryTableProps<T>) {
  const generateClassName = (rowName: string, columnName: string, isNumber: boolean): string => {
    return "text-nowrap"
      + (isNumber ? " text-end" : "")
      + ((rowName.includes('合計') || columnName.includes('合計')) ? " bg-light" : "");
  }

  return (
    <>
      <div className="mb-3 fixed-table-parent">
        <Table className="fixed-table">
          <thead>
            <tr>
              <th></th>
              {Array.from((Array.from(props.summaryObject[0].values())[0]).keys()).map((k) => (
                <th key={k} className={generateClassName("", k, false)}>{k}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.summaryObject.map((summaryMap) => (
              Array.from(summaryMap.entries()).map(([key, value]) => (
                <tr key={key}>
                  <th className={generateClassName(key, "", false)}>{key}</th>
                  {Array.from(value.entries()).map(([k, v]) => (
                    <td key={k} className={generateClassName(key, k, true)}><props.Cell input={v}/></td>
                  ))}
                </tr>
              ))
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default SummaryTable;