import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {
  IdValue,
  FundListValue,
  PaymentCategoryValue,
  IncomeCategoryValue,
  TagValue,
  ShortcutValue
} from '../FirebaseType';

import { judgeType } from '../util/Firebase';

interface RegisterProps {
  containerRef: any;
  fundList: IdValue<FundListValue>[] | undefined;
  paymentCategory: IdValue<PaymentCategoryValue>[] | undefined;
  incomeCategory: IdValue<IncomeCategoryValue>[] | undefined;
  tag: IdValue<TagValue>[] | undefined;
  shortcut: IdValue<ShortcutValue>[] | undefined;
  registerForm: any;
  onSubmit: any;
}

function Register(props: RegisterProps) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if(location.state) {
      if(location.state.id) props.registerForm.setValue('id', location.state.id);
      if(location.state.value.date) props.registerForm.setValue('date', location.state.value.date);
      const type = judgeType(location.state);
      switch(type) {
        case 'payment':
          props.registerForm.setValue('type', 'payment');
          props.registerForm.setValue('from', location.state.value.from);
          props.registerForm.setValue('paymentCategory', location.state.value.category);
          break;
        case 'income':
          props.registerForm.setValue('type', 'income');
          props.registerForm.setValue('to', location.state.value.to);
          props.registerForm.setValue('incomeCategory', location.state.value.category);
          break;
        case 'transfer':
          props.registerForm.setValue('type', 'transfer');
          props.registerForm.setValue('from', location.state.value.from);
          props.registerForm.setValue('to', location.state.value.to);
          break;
        case 'evaluation':
          props.registerForm.setValue('type', 'evaluation');
          props.registerForm.setValue('evaluationFromTo', location.state.value.from);
          break;
        default:
          break;
      }
      if(location.state.value.amount) props.registerForm.setValue('amount', location.state.value.amount);
      if(location.state.value.memo) {
        let memoArray: string[] = location.state.value.memo.split("; ");
        if(props.tag && props.tag.length > 0) {
          let tagNames = props.tag.map((t) => t.value.name);
          props.registerForm.setValue('memoTagOnly', memoArray.filter((item) => tagNames.includes(item)));
          memoArray = memoArray.filter((item) => !tagNames.includes(item));
        }
        props.registerForm.setValue('memoWithoutTag', memoArray.join("; "));
      }
      if(location.state.value.isRegular) props.registerForm.setValue('isRegular', location.state.value.isRegular);
      if(location.state.value.isAdvance) props.registerForm.setValue('isAdvance', location.state.value.isAdvance);
      if(location.state.value.isReimbursed) props.registerForm.setValue('isReimbursed', location.state.value.isReimbursed);
      if(location.state.value.isDisabled) props.registerForm.setValue('isDisabled', location.state.value.isDisabled);
      if(location.state.value.sortId) props.registerForm.setValue('sortId', location.state.value.sortId);
      if(location.state.value.dataSource) props.registerForm.setValue('dataSource', location.state.value.dataSource);

      navigate(location.pathname, {replace: true});
    }
  }, [location.state, location.pathname, navigate, props.registerForm, props.tag]);

  const watchType = props.registerForm.watch('type');
  const watchIsRegular = props.registerForm.watch('isRegular');

  useEffect(() => {
    if(props.fundList) {
      if(props.registerForm.getValues('from') === "") {
        props.registerForm.resetField('from', {defaultValue: props.fundList[0].value.name});
      }
      if(props.registerForm.getValues('to') === "") {
        props.registerForm.resetField('to', {defaultValue: props.fundList[0].value.name});
      }
      if(props.registerForm.getValues('evaluationFromTo') === "") {
        props.registerForm.resetField('evaluationFromTo', {defaultValue: props.fundList[0].value.name});
      }
    }
  }, [watchType, props.fundList, props.registerForm]);

  useEffect(() => {
    if(props.paymentCategory) {
      if(props.registerForm.getValues('paymentCategory') === "") {
        props.registerForm.resetField('paymentCategory', {defaultValue: props.paymentCategory[0].value.name});
      }
    }
  }, [watchType, props.paymentCategory, props.registerForm]);

  useEffect(() => {
    if(props.incomeCategory) {
      if(props.registerForm.getValues('incomeCategory') === "") {
        props.registerForm.resetField('incomeCategory', {defaultValue: props.incomeCategory[0].value.name});
      }
    }
  }, [watchType, props.incomeCategory, props.registerForm]);

  const watchMemoWithoutTag = props.registerForm.watch('memoWithoutTag');
  const watchMemoTagOnly = props.registerForm.watch('memoTagOnly');
  useEffect(() => {
    props.registerForm.setValue('memo', watchMemoWithoutTag + (watchMemoWithoutTag.length>0 && watchMemoTagOnly.length>0 ? "; " : "") + watchMemoTagOnly.join("; "));
  }, [watchMemoWithoutTag, watchMemoTagOnly, props.registerForm]);

  return (
    <Form onSubmit={props.registerForm.handleSubmit((data: any) => {
      props.containerRef?.current?.scrollIntoView({behavior: 'instant', block: 'start'});
      props.onSubmit(data);
      navigate('/list');
    })}>
      {props.registerForm.getValues('id') &&
        <Alert variant="warning">修正中です</Alert>
      }

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>日付</Form.Label>
        <Form.Control
          type="date"
          isInvalid={props.registerForm.formState.errors.date}
          {...props.registerForm.register('date', {
            required: '必須項目です'
          })} />
        <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.date?.message}</Form.Control.Feedback>
      </Form.Group>

      <ButtonGroup className="mb-3">
        <ToggleButton
          type="radio"
          variant="outline-primary"
          key="payment"
          id="payment"
          value="payment"
          checked={watchType === "payment"}
          onChange={(e)=>props.registerForm.setValue('type', e.currentTarget.value)}
        >
          支払
        </ToggleButton>
        <ToggleButton
          type="radio"
          variant="outline-primary"
          key="income"
          id="income"
          value="income"
          checked={watchType === "income"}
          onChange={(e)=>props.registerForm.setValue('type', e.currentTarget.value)}
        >
          収入
        </ToggleButton>
        <ToggleButton
          type="radio"
          variant="outline-primary"
          key="transfer"
          id="transfer"
          value="transfer"
          checked={watchType === "transfer"}
          onChange={(e)=>props.registerForm.setValue('type', e.currentTarget.value)}
        >
          資金移動
        </ToggleButton>
        <ToggleButton
          type="radio"
          variant="outline-primary"
          key="evaluation"
          id="evaluation"
          value="evaluation"
          checked={watchType === "evaluation"}
          onChange={(e)=>props.registerForm.setValue('type', e.currentTarget.value)}
        >
          資金評価
        </ToggleButton>
      </ButtonGroup>
      <Container>
        {watchType !=="evaluation" &&
          <Row className="mb-3">
            <Form.Group as={Col} controlId="main-form-from">
              {watchType !== "income" &&
                <>
                  <Form.Label>資金FROM</Form.Label>
                  <Form.Select
                    isInvalid={props.registerForm.formState.errors.from}
                    {...props.registerForm.register('from', {
                      required: '必須項目です'
                    })}>
                    {props.fundList && props.fundList.map((idVal) => {
                      return <option key={idVal.id} value={idVal.value.name}>{idVal.value.name}</option>
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.from?.message}</Form.Control.Feedback>
                </>
              }
            </Form.Group>

            <Form.Group as={Col} controlId="main-form-to">
              {watchType !== "payment" &&
                <>
                  <Form.Label>資金TO</Form.Label>
                  <Form.Select
                    isInvalid={props.registerForm.formState.errors.to}
                    {...props.registerForm.register('to', {
                      required: '必須項目です'
                    })}>
                    {props.fundList && props.fundList.map((idVal) => {
                      return <option key={idVal.id} value={idVal.value.name}>{idVal.value.name}</option>
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.to?.message}</Form.Control.Feedback>
                </>
              }
            </Form.Group>
          </Row>
        }

        {watchType === "evaluation" &&
          <Form.Group className="mb-3" controlId="main-form-evaluation-from-to">
            <Form.Label>資金</Form.Label>
            <Form.Select
              isInvalid={props.registerForm.formState.errors.evaluationFromTo}
              {...props.registerForm.register('evaluationFromTo', {
                required: '必須項目です'
              })}>
              {props.fundList && props.fundList.map((idVal) => {
                return <option key={idVal.id} value={idVal.value.name}>{idVal.value.name}</option>
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.evaluationFromTo?.message}</Form.Control.Feedback>
          </Form.Group>
        }

        {watchType === "payment" &&
          <Form.Group className="mb-3" controlId="main-form-payment-category">
            <Form.Label>カテゴリ</Form.Label>
            <Form.Select
              isInvalid={props.registerForm.formState.errors.paymentCategory}
              {...props.registerForm.register('paymentCategory', {
                required: '必須項目です'
              })}>
              {props.paymentCategory && props.paymentCategory
                .filter((idVal) => (idVal.value.regular === watchIsRegular))
                .map((idVal) => {
                  return <option key={idVal.id} value={idVal.value.name}>{idVal.value.name}</option>
                })
              }
            </Form.Select>
            <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.paymentCategory?.message}</Form.Control.Feedback>
          </Form.Group>
        }

        {watchType === "income" &&
          <Form.Group className="mb-3" controlId="main-form-income-category">
            <Form.Label>カテゴリ</Form.Label>
            <Form.Select
              isInvalid={props.registerForm.formState.errors.incomeCategory}
              {...props.registerForm.register('incomeCategory', {
                required: '必須項目です'
              })}>
              {props.incomeCategory && props.incomeCategory
                .filter((idVal) => (idVal.value.regular === watchIsRegular))
                .map((idVal) => {
                  return <option key={idVal.id} value={idVal.value.name}>{idVal.value.name}</option>
                })
              }
            </Form.Select>
            <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.incomeCategory?.message}</Form.Control.Feedback>
          </Form.Group>
        }
      </Container>

      <Form.Group className="mb-3" controlId="main-form-amount">
        <Form.Label>金額</Form.Label>
        <Form.Control
          type="number"
          placeholder="How much"
          isInvalid={props.registerForm.formState.errors.amount}
          {...props.registerForm.register('amount', {
            required: '必須項目です',
            min: {value: 0, message: '0円以上の値を入力してください'},
            valueAsNumber: true
          })} />
        <Form.Control.Feedback type="invalid">{props.registerForm.formState.errors.amount?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="main-form-memo">
        <Form.Label>メモ</Form.Label>
        <Form.Control type="text" placeholder="Memo" {...props.registerForm.register('memoWithoutTag')} />
        <ToggleButtonGroup
          className="mt-1 btn-group-custom"
          type="checkbox"
          value={watchMemoTagOnly}
          onChange={(v) => props.registerForm.setValue('memoTagOnly', v)}
        >
          {props.tag && props.tag.map((item) =>
            <ToggleButton
              className="me-1 mb-1"
              id={item.id}
              key={item.id}
              variant="outline-secondary"
              size="sm"
              value={item.value.name}
            >
              {item.value.name}
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check type="switch" label="定期" {...props.registerForm.register('isRegular')} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check type="switch" label="立替" {...props.registerForm.register('isAdvance')} />
      </Form.Group>

      <Button type="submit" className="me-1" variant="primary">登録</Button>
      <Button className="me-1" variant="secondary" onClick={() => props.registerForm.reset()}>キャンセル</Button>
      <br />
      <ButtonGroup size="sm" className="mt-1 btn-group-custom">
        {props.shortcut && props.shortcut.map((item) =>
          <Button
            className="me-1 mb-1"
            key={item.id}
            variant="outline-primary"
            onClick={() => {
              props.registerForm.reset();
              navigate('/register', {state: {value: item.value}, replace: true});
            }}
          >
            {item.value.name}
          </Button>
        )}
      </ButtonGroup>
    </Form>
  );
}

export default Register;