import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { database } from '../firebaseConfig';
import { ref, push, runTransaction } from "firebase/database";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { GoCheck } from 'react-icons/go';

import { SnapshotVal, IdValue, FundListValue } from '../FirebaseType';

import DeleteModal from './DeleteModal';
import ListSortable from './ListSortable';

import { addItem } from '../util/Firebase';

type Inputs = {
  id: string;
  name: string;
  check: boolean;
};

interface SettingFundListProps {
  list: IdValue<FundListValue>[] | undefined;
  loginUserUid: string;
}

function SettingFundList(props: SettingFundListProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<IdValue<FundListValue>>();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      id: "",
      name: "",
      check: false
    }
  });

  const watchId = watch('id');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    runTransaction(ref(database, "/v3/" + props.loginUserUid + "/fundList"), (post: SnapshotVal<FundListValue>) => {
      if(!post) post = {};

      if(watchId) {
        post[watchId].name = getValues('name');
        post[watchId].check = getValues('check');
      }
      else {
        const key = push(ref(database, "/v3/" + props.loginUserUid + "/fundList")).key || '';
        addItem<SnapshotVal<FundListValue>>(post, key, {
          name: getValues('name'),
          check: getValues('check'),
          sortNumber: 0
        })
      }

      return post;
    });
    reset();
  }

  const onCancel = () => {
    reset();
  };

  const onEdit = (id: IdValue<FundListValue>) => {
    setValue('id', id.id);
    setValue('name', id.value.name);
    setValue('check', id.value.check);
  };

  const onDelete = (id: IdValue<FundListValue>) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <h5>資金</h5>
      <hr />
      {props.loginUserUid &&
        <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
          {watchId &&
            <Alert variant="warning">修正中です</Alert>
          }
          <Row className="d-flex align-items-end flex-wrap">
            <Col xs={5}>
              <Form.Group>
                <Form.Label>名前</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: '必須項目です'
                  })} />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group>
                <Form.Label>チェック対象</Form.Label>
                <Form.Check type="switch" className="switch-custom" {...register('check')} />
              </Form.Group>
            </Col>
            <Col className="pt-2 z-3" xs={4}>
              <Button type="submit" variant="primary" size="sm" className="mt-lg-4 mt-1 me-1">登録</Button>
              <Button variant="secondary" size="sm" className="mt-lg-4 mt-1 me-1" onClick={onCancel}>キャンセル</Button>
            </Col>
          </Row>
        </Form>
      }

      {!props.list && <p>Loading...</p>}

      {props.list &&
        <ListSortable<FundListValue>
          list={props.list}
          databasePath={"/v3/" + props.loginUserUid + "/fundList"}
          columnWidthLg={[1, 4, 3, 4]}
          columnWidthXs={[1, 4, 3, 4]}
          columnJSX={[
            (item) => <>{item.value.name}</>,
            (item) => <>{item.value.check ? <GoCheck /> : null}</>
          ]}
          onEdit={onEdit}
          editingId={watchId}
          onDelete={onDelete}
        />
      }

      <DeleteModal<FundListValue>
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        databasePath={"/v3/" + props.loginUserUid + "/fundList"}
      />
    </>
  );
}

export default SettingFundList;