// Date型をYYYY-MM-DDのString型に変換
export const dateToHyphenString = (argDate: Date): string => {
  var dt = argDate;
  var y = dt.getFullYear();
  var m = ("0" + (dt.getMonth()+1)).slice(-2);
  var d = ("0" + dt.getDate()).slice(-2);
  var result = y + "-" + m + "-" + d;
  return result;
}

// Date型をYYYY-MMのString型に変換
export const dateToMonthHyphenString = (argDate: Date): string => {
  var dt = argDate;
  var y = dt.getFullYear();
  var m = ("0" + (dt.getMonth()+1)).slice(-2);
  var result = y + "-" + m;
  return result;
}

// Date型をYYYYMMDDHHMMSSのString型に変換
export const dateToStringWithTime = (argDate: Date): string => {
  var dt = argDate;
  var y = dt.getFullYear();
  var mo = ("0" + (dt.getMonth()+1)).slice(-2);
  var d = ("0" + dt.getDate()).slice(-2);
  var h = ("0" + dt.getHours()).slice(-2);
  var mi = ("0" + dt.getMinutes()).slice(-2);
  var s = ("0" + dt.getSeconds()).slice(-2);
  var result = y + mo + d + h + mi + s;
  return result;
}

// Date型をYYYY/MM/DD HH:MM:SSのString型に変換
export const dateToSlashStringWithTime = (argDate: Date): string => {
  var dt = argDate;
  var y = dt.getFullYear();
  var mo = ("0" + (dt.getMonth()+1)).slice(-2);
  var d = ("0" + dt.getDate()).slice(-2);
  var h = ("0" + dt.getHours()).slice(-2);
  var mi = ("0" + dt.getMinutes()).slice(-2);
  var s = ("0" + dt.getSeconds()).slice(-2);
  var result = y + "/" + mo + "/" + d + " " + h + ":" + mi + ":" + s;
  return result;
}

// YYYY-MM-DDのString型をYYYY/MM/DD(AA)のString型（HTMLタグ含む）に変換
export const hyphenStringToJSX = (argDate: string) => {
  const day = new Date(argDate.slice(0, 10)).getDay();
  const dayString = [
    {name: "日", textColor: "red"},
    {name: "月", textColor: ""},
    {name: "火", textColor: ""},
    {name: "水", textColor: ""},
    {name: "木", textColor: ""},
    {name: "金", textColor: ""},
    {name: "土", textColor: "blue"}
  ][day];

  return (
    <>
      {argDate.slice(0, 4) + "/" + argDate.slice(5, 7) + "/" + argDate.slice(8, 10)}
      (<span style={{color: dayString.textColor}}>{dayString.name}</span>)
    </>
  );
}