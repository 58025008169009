import { SummaryList } from '../FirebaseType';

import SummaryTable from '../common/SummaryTable';

interface SummaryAdvanceProps {
  summaryList: SummaryList | undefined;
}

function SummaryAdvance(props: SummaryAdvanceProps) {
  return (
    <>
      <h5>立替（全期間）</h5>

      {!props?.summaryList?.advanceSummaryList && <p>Loading...</p>}

      {props?.summaryList?.advanceSummaryList && <>
        <SummaryTable<number>
          summaryObject={[props.summaryList.advanceSummaryList]}
          Cell={(props) => (
            <>
              {props.input > 0 ? <p className="text-warning">{Number(props.input).toLocaleString()}</p> : <p>{Number(props.input).toLocaleString()}</p>}
            </>
          )}
        />
      </>}
    </>
  );
}

export default SummaryAdvance;