import { useEffect, useState } from 'react';

import { storage } from '../firebaseConfig';
import { ref, listAll, uploadBytes, ListResult, getDownloadURL, deleteObject } from 'firebase/storage';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { FaTrash, FaDownload } from 'react-icons/fa';

interface UploadProps {
  loginUserUid: string;
}

function Upload(props: UploadProps) {
  const [fileList, setFileList] = useState<ListResult>();

  const [uploadedCount, setUploadedCount] = useState<number>(0);
  const [uploadedTotalCount, setUploadedTotalCount] = useState<number>(0);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();

  useEffect(() => {
    if(props.loginUserUid) {
      listAll(ref(storage, "/v3/" + props.loginUserUid + "/upload/")).then((list) => {setFileList(list);});
    }
  }, [props.loginUserUid]);

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
    setDeleteId(undefined);
  }

  return (
    <>
      <h5>ファイル登録</h5>
      <hr />
      {props.loginUserUid &&
        <Form.Group controlId="formFileMultiple" className="mb-3">
          <Form.Control type="file" multiple onChange={async (e) => {
            const files = (e.target as HTMLInputElement).files;
            if(files) {
              setUploadedTotalCount(files.length);
              // ブラウザにより同時接続数が制限されるため、順番に処理する
              for (const file of Array.from(files)) {
                await uploadBytes(ref(storage, "v3/" + props.loginUserUid + "/upload/" + file.name), file).then((snapshot) => {
                  console.log(snapshot);
                  setUploadedCount((uploadedCount) => uploadedCount + 1);
                });
              }
              listAll(ref(storage, "v3/" + props.loginUserUid + "/upload/")).then((list) => {setFileList(list);});
              setTimeout(() => {
                e.target.value="";
                setUploadedCount(0);
                setUploadedTotalCount(0);
              }, 3000);
            }
          }} />
          {uploadedTotalCount > 0 && <ProgressBar
            animated
            now={(uploadedCount / uploadedTotalCount) * 100}
            label={`${uploadedCount} / ${uploadedTotalCount} ファイルアップロード完了`}
          />}
        </Form.Group>
      }

      <h5>ファイル一覧</h5>
      <hr />

      {!fileList && <p>Loading...</p>}

      {fileList?.items.map((file) => (
        <Row key={file.name} className="border-bottom py-2">
          <Col xs={10}>{file.name}</Col>
          <Col xs={2}>
            <FaDownload size="1.2em" className="me-1 pointer" onClick={async () => {
              getDownloadURL(ref(storage, "v3/" + props.loginUserUid + "/upload/" + file.name)).then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                  const blob = xhr.response;
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.download = file.name;
                  a.href = window.URL.createObjectURL(blob);
                  a.click();
                };
                xhr.open('GET', url);
                xhr.send();
              }).catch((error) => {
                console.log(error);
              });
            }} />
            <FaTrash size="1.2em" className="me-1 pointer" onClick={async () => {
              setShowDeleteModal(true);
              setDeleteId(file.name);
            }} />
          </Col>
        </Row>
      ))}

      <Modal show={showDeleteModal} onHide={()=>onDeleteCancel()}>
        <Modal.Header closeButton>
          <Modal.Title>削除</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ファイル「{deleteId}」を削除します
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>onDeleteCancel()}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={async ()=>{
            await deleteObject(ref(storage, "/v3/" + props.loginUserUid + "/upload/" + deleteId)).then(() => {
              console.log(deleteId + " is deleted");
            });
            listAll(ref(storage, "/v3/" + props.loginUserUid + "/upload/")).then((list) => {setFileList(list);});
            onDeleteCancel();
          }}>
            削除
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Upload;