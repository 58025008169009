import { useState, useEffect } from 'react';

import { database } from '../firebaseConfig';
import { ref, push, runTransaction } from "firebase/database";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaEdit, FaTrash } from 'react-icons/fa';
import { GoGrabber } from 'react-icons/go';

import { ReactSortable } from 'react-sortablejs';

import { SnapshotVal, IdValue, SettingListValueAbstract } from '../FirebaseType';

import { addItem, deleteItem } from '../util/Firebase';

interface ListSortableProps<T extends SettingListValueAbstract> {
  list: IdValue<T>[];
  databasePath: string;
  columnWidthLg: number[];
  columnWidthXs: number[];
  columnJSX: ((item: IdValue<T>) => JSX.Element)[];
  onEdit: (item: IdValue<T>) => void;
  editingId: string;
  onDelete: (item: IdValue<T>) => void;
}

function ListSortable<T extends SettingListValueAbstract>(props: ListSortableProps<T>) {
  const [listSortable, setListSortable] = useState<IdValue<T>[]>([]);

  useEffect(() => {
    setListSortable(props.list);
  }, [props.list]);

  return (
    <>
      <ReactSortable
        list={listSortable}
        setList={setListSortable}
        delay={200}
        delayOnTouchOnly={true}
        onUpdate={(event) => {
          if(event && typeof event.oldIndex !== "undefined" && typeof event.newIndex !== "undefined") {
            const oldIndex: number = event.oldIndex;
            const newIndex: number = event.newIndex;
            runTransaction(ref(database, props.databasePath), (post: SnapshotVal<T>) => {
              if(post) {
                const old = listSortable[oldIndex];
                deleteItem<SnapshotVal<T>>(post, old.id);

                const key = push(ref(database, props.databasePath)).key || '';
                const value = Object.assign({}, old.value);
                value.sortNumber = newIndex;
                addItem<SnapshotVal<T>>(post, key, value);
              }
              return post;
            });
          }
        }}>
        {listSortable?.map((item) => (
          <Row key={item.id} className="border-bottom py-2">
            <Col xs={props.columnWidthXs[0]} lg={props.columnWidthLg[0]} className="grab-grabbing"><GoGrabber /></Col>
            {props.columnJSX.map((i, index) => (
              <Col key={index} xs={props.columnWidthXs[index+1]} lg={props.columnWidthLg[index+1]} className="grab-grabbing">{i(item)}</Col>
            ))}
            <Col xs={props.columnWidthXs[props.columnWidthXs.length-1]} lg={props.columnWidthLg[props.columnWidthLg.length-1]}>
              <FaEdit size="1.2em" color={item.id === props.editingId ? "red" : ""} className="me-1 pointer" onClick={() => props.onEdit(item)} />
              <FaTrash size="1.2em" className="me-1 pointer" onClick={() => props.onDelete(item)} />
            </Col>
          </Row>
        ))}
      </ReactSortable>
    </>
  );
}

export default ListSortable;