import { HistoryListValue, HistoryList } from '../FirebaseType';

import SummaryTable from '../common/SummaryTable';
import HistoryGraph from './HistoryGraph';

interface CellProps {
  input: HistoryListValue;
}

function Cell(props: CellProps) {
  let rate = 0;
  if(props.input.transferTotal > 0) {
    rate = Number(((props.input.evaluationTotal - props.input.transferTotal) * 100 / props.input.transferTotal).toFixed(1));
  }

  return <>
    {Number(props.input.transferTotal).toLocaleString()}
    <br />
    {props.input.evaluationFlag ? <b>{Number(props.input.evaluationTotal).toLocaleString()}</b> : Number(props.input.evaluationTotal).toLocaleString()}
    <br />
    {props.input.transferTotal > 0
      ? (rate > 0
        ? <p className="text-success">{rate}%</p>
        : (rate < 0
          ? <p className="text-danger">{rate}%</p>
          : <p>{rate}%</p>))
      : (props.input.transferTotal <= 0 && props.input.evaluationTotal > 0
        ? <p className="text-success">+∞</p>
        : <p></p>)
    }
  </>;
}

interface HistoryProps {
  historyList: HistoryList | undefined;
}

function History(props: HistoryProps) {
  return (
    <>
      <h5>資金変動履歴</h5>

      {!props.historyList && <p>Loading...</p>}

      {props.historyList && <>
        <HistoryGraph
          historyList={props.historyList}
        />
        <SummaryTable<HistoryListValue>
          summaryObject={[props.historyList]}
          Cell={Cell}
        />
      </>}
    </>
  );
}

export default History;