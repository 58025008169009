import { SummaryList } from '../FirebaseType';

import SummaryTable from '../common/SummaryTable';

interface SummaryMonthCompareProps {
  summaryList: SummaryList | undefined;
}

function SummaryMonthCompare(props: SummaryMonthCompareProps) {
  return (
    <>
      <h5>月比較支出</h5>

      {!props?.summaryList?.paymentSummaryList && <p>Loading...</p>}

      {props?.summaryList?.paymentSummaryList && <>
        <SummaryTable<number>
          summaryObject={[
            props.summaryList.paymentSummaryList.paymentSummaryMonthList,
            props.summaryList.paymentSummaryList.paymentSummaryUnregularMonthList,
            props.summaryList.paymentSummaryList.paymentSummaryRegularMonthList
          ]}
          Cell={(props) => (
            <>
              {props.input > 0 ? <p className="text-danger">{Number(props.input).toLocaleString()}</p> : <p>{Number(props.input).toLocaleString()}</p>}
            </>
          )}
        />
      </>}

      <h5>月比較収入</h5>

      {!props?.summaryList?.incomeSummaryList && <p>Loading...</p>}

      {props?.summaryList?.incomeSummaryList && <>
        <SummaryTable<number>
          summaryObject={[
            props.summaryList.incomeSummaryList.incomeSummaryMonthList,
            props.summaryList.incomeSummaryList.incomeSummaryUnregularMonthList,
            props.summaryList.incomeSummaryList.incomeSummaryRegularMonthList
          ]}
          Cell={(props) => (
            <>
              {props.input > 0 ? <p className="text-success">{Number(props.input).toLocaleString()}</p> : <p>{Number(props.input).toLocaleString()}</p>}
            </>
          )}
        />
      </>}
    </>
  );
}

export default SummaryMonthCompare;