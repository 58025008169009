import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';

interface SearchProps {
  searchForm: any;
}

function Search(props: SearchProps) {
  const watchMonth = props.searchForm.watch('month');
  const [showWarnModal, setShowWarnModal] = useState<boolean>(false);

  const onWarnCancel = () => {
    props.searchForm.setValue('month', watchMonth);
    setShowWarnModal(false);
  };

  const watchDisplayIsDisabled = props.searchForm.watch('displayIsDisabled');
  const watchDisplayOnlyIsRegular = props.searchForm.watch('displayOnlyIsRegular');
  const watchDisplayOnlyIsAdvance = props.searchForm.watch('displayOnlyIsAdvance');
  const watchDisplayOnlyIsUncheck = props.searchForm.watch('displayOnlyIsUncheck');

  return (
    <>
      <Row className="mb-3">
        <Form.Label column xs={12} md={2}>
          月指定
        </Form.Label>
        <Col xs={12} md={10}>
          <Form.Control type="month" value={watchMonth} onChange={(e)=>{
            if(e.currentTarget.value === "") {
              setShowWarnModal(true);
            }
            else {
              props.searchForm.setValue('month', e.currentTarget.value);
            }
          }}  />
        </Col>
      </Row>
      <Row className="mb-3">
        <Form.Label column xs={12} md={2}>
          検索キーワード
        </Form.Label>
        <Col xs={12} md={10}>
          <InputGroup>
            <Form.Control type="text" disabled={watchMonth === ""} isInvalid={watchMonth === ""} {...props.searchForm.register('search')} />
            <Button variant="outline-secondary" disabled={watchMonth === ""} onClick={() => {props.searchForm.resetField('search');}} >×</Button>
            <Form.Control.Feedback type="invalid">月を指定しない状態では検索キーワードの変更はできません。</Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Form.Label column xs={12} md={2}>
          検索条件
        </Form.Label>
        <Col xs={12} md={10}>
          <ToggleButton
            className="me-1 mb-1"
            id="search-form-display-is-disabled"
            type="checkbox"
            variant="outline-secondary"
            size="sm"
            checked={watchDisplayIsDisabled}
            value="1"
            onChange={(e) => props.searchForm.setValue('displayIsDisabled', !watchDisplayIsDisabled)}
          >
            有効以外も表示
          </ToggleButton>
          <ToggleButton
            className="me-1 mb-1"
            id="search-form-display-only-is-regular"
            type="checkbox"
            variant="outline-secondary"
            size="sm"
            checked={watchDisplayOnlyIsRegular}
            value="1"
            onChange={(e) => props.searchForm.setValue('displayOnlyIsRegular', !watchDisplayOnlyIsRegular)}
          >
            定期のみ表示
          </ToggleButton>
          <ToggleButton
            className="me-1 mb-1"
            id="search-form-display-only-is-advance"
            type="checkbox"
            variant="outline-secondary"
            size="sm"
            checked={watchDisplayOnlyIsAdvance}
            value="1"
            onChange={(e) => props.searchForm.setValue('displayOnlyIsAdvance', !watchDisplayOnlyIsAdvance)}
          >
            立替のみ表示
          </ToggleButton>
          <ToggleButton
            className="me-1 mb-1"
            id="search-form-display-only-is-uncheck"
            type="checkbox"
            variant="outline-secondary"
            size="sm"
            checked={watchDisplayOnlyIsUncheck}
            value="1"
            onChange={(e) => props.searchForm.setValue('displayOnlyIsUncheck', !watchDisplayOnlyIsUncheck)}
          >
            未チェックのみ表示
          </ToggleButton>
        </Col>
      </Row>

      <Modal show={showWarnModal} onHide={()=>onWarnCancel()}>
        <Modal.Header closeButton>
          <Modal.Title>確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>月を指定しない場合、通信料や処理時間が増加する可能性があります。検索キーワードを指定し、条件を絞り込むことをおすすめします。</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>onWarnCancel()}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={()=>{
            props.searchForm.setValue('month', "");
            setShowWarnModal(false);
          }}>
            月指定を削除
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Search;