import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

import { HistoryList } from '../FirebaseType';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

interface HistoryGraphProps {
  historyList: HistoryList;
}

function HistoryGraph(props: HistoryGraphProps) {
  const labels = Array.from(props.historyList.keys()).slice().reverse();

  const colorArray = ["#4472C4", "#ED7D31", "#A5A5A5", "#FFC000", "#5B9BD5", "#70AD47", "#264478", "#9E480E", "#636363", "#997300", "#255E91", "#43682B", "#698ED0", "#F1975A", "#B7B7B7", "#FFCD33", "#7CAFDD", "#8CC168", "#335AA1", "#D26012", "#848484", "#CC9A00", "#327DC2", "#5A8A39", "#8FAADC", "#F4B183", "#C9C9C9", "#FFD966", "#9DC3E6", "#A9D18E"];
  const datasets: {
    fill: boolean;
    label: string;
    data: (number | undefined)[];
    borderWidth: number;
    backgroundColor: string
  }[] = Array.from((Array.from(props.historyList.values())[0]).keys())
    .filter((k) => k !== '合計')
    .map((k, index) => ({
      fill: true,
      label: k,
      data: labels.map((date) => props.historyList.get(date)?.get(k)?.evaluationTotal),
      borderWidth: 0,
      backgroundColor: colorArray[index]
    }));

  const graphData = {
    labels: labels,
    datasets: datasets
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false,
        text: '資金評価額'
      },
      tooltip: {
        enabled: true,
        mode: 'nearest' as const,
        position: 'nearest' as const
      },
      legend: {
        display: window.innerWidth > 576,
        position: 'top' as const,
        title: {
          display: false,
          text: '凡例'
        }
      },
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0.1,
        showLine: false
      }
    },
    scales: {
      x: {
        title: {
          display: false,
          text: '日付'
        },
        type: "time" as const,
        ticks: {
          source: "labels" as const
        },
        time: {
          displayFormats: {
            hour: "YYYY/MM/DD hA",
            day: "YYYY/MM/DD",
            week: "YYYY/MM/DD",
            month: "YYYY/MM",
            quarter: "YYYY-Q[Q]",
            year: "YYYY",
          }
        },
        grid: {
          display: true,
          drawOnChartArea: true
        }
      },
      y: {
        display: window.innerWidth > 576,
        stacked: true,
        title: {
          display: false,
          text: '資金評価額'
        },
        min: 0,
        grid: {
          display: true,
          drawOnChartArea: true
        }
      }
    }
  };

  return (
    <Line
      data={graphData}
      options={options}
    />
  )
}

export default HistoryGraph;